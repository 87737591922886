import type { NatomaRuntimeConfig } from './config';
import {
  type Environment,
  isRemoteEnvironment as checkEnvironment
} from '@natomalabs/common/environment';

export const RUNTIME_ENV_SCRIPT_ID = 'runtime-config';

/**
 * Fetches and parses the runtime configuration environment from the dynamically
 * rendered (server-side) script tag in the DOM.
 *
 * If the appropriate script tag is not found, or this code is evaluated in a
 * non-browser environment, this function will return undefined.
 */
export const getRuntimeConfig = (): NatomaRuntimeConfig | undefined => {
  if (window === undefined || !document) return;

  const configScript = document.getElementById(
    RUNTIME_ENV_SCRIPT_ID
  ) as HTMLScriptElement;
  return configScript ? JSON.parse(configScript.innerText) : undefined;
};

/**
 * Returns an appropriately-type environment string given the current
 * config object.
 */
export function getEnvironment(config: NatomaRuntimeConfig): Environment {
  const { natoma } = config;
  if (!natoma.environment) {
    return 'local';
  }
  return natoma.environment as Environment;
}

/**
 * Returns `true` if the current environment is a remote env, `false` otherwise.
 * This is useful to control monitoring or other codepaths that will behave
 * differently in cloud environments vs local or test environments.
 */
export function isRemoteEnvironment(config: NatomaRuntimeConfig): boolean {
  const currentEnv = getEnvironment(config);
  if (checkEnvironment(currentEnv)) return true;
  return false;
}
