import { Box, Button, Divider, List, ListItem, Link } from '@mui/material';
import { H2, H4, H5 } from './Typography';
import { RegisterPath, orgUrl } from '../lib/paths';
import { useGetMyOrgsQuery } from '@/__generated__/graphql';
import LoadingState from './LoadingState';
import { redirect } from 'next/navigation';
import useEnvironment from '../providers/Environment';
import HomeViewPaper from './HomeViewPaper';
import { BuildingIcon } from '@/src/icons';

export default function HomeOrgPicker() {
  const { loading, data } = useGetMyOrgsQuery();
  const { natoma } = useEnvironment();
  if (loading) return <LoadingState />;
  if (data?.myOrgs.length === 0) {
    redirect(RegisterPath);
  }
  return (
    <HomeViewPaper>
      <H2 sx={(theme) => ({ fontWeight: 700, lineHeight: theme.spacing(4.5) })}>
        Hello, it looks like you already have a Natoma account!
      </H2>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2.5 }}>
        <List
          sx={{
            display: 'flex',
            flexDirection: 'column',
            mt: 4,
            py: 0,
            gap: 2.5
          }}
        >
          {data?.myOrgs.map((org, i) => (
            <ListItem key={i} sx={{ p: 0 }}>
              <Button
                href={orgUrl(org.slug, natoma.rootDomain)}
                LinkComponent={Link}
                startIcon={<BuildingIcon />}
              >
                {org.displayName || 'unknown'}
              </Button>
            </ListItem>
          ))}
        </List>
        <Divider />
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
          <H5
            sx={(theme) => ({
              fontWeight: 400,
              color: theme.palette.grey[500]
            })}
          >
            Or create a new organization to get started
          </H5>
          <Button fullWidth href="/register">
            <H4 fontWeight={600}>Create a new organization</H4>
          </Button>
        </Box>
      </Box>
    </HomeViewPaper>
  );
}
