export const RegisterPath = '/register';

export const LoginPath = '/login';
export const LogoutPath = '/logout';
export const SignUpPath = '/signup';
export const InvitePath = '/invite';
export const IntegrationsPath = '/integrations';
export const ErrorPath = '/error';

export const IntegrationsConnectPath = '/integrations/connect';

export const ServicesPath = '/services';

export const ServiceDetailsPath = (serviceId: string) =>
  `/services/${serviceId}`;

export const IdentityDetailsPath = (identityId: string) =>
  `/identities/${identityId}`;

export const orgUrl = (slug: string, domain: string) =>
  `https://${slug}.${domain}`;

export const integrationAuthUrl = (
  integrationId: string,
  inputParams: string,
  orgSlug: string,
  domain: string
) =>
  `https://api.${domain}/oauth/${integrationId}/auth?org=${orgSlug}&input=${inputParams}`;

export const JobDetailsPath = (jobId: string) => `/jobs/${jobId}`;

export const SettingsPathGeneral = '/settings/general';
export const SettingsPathUsers = '/settings/users';
export const SettingsPathAccess = '/settings/access';

export const DirectoryPath = '/directory';

export const SettingsPath = (tab: string) => `/settings/${tab.toLowerCase()}`;

export const AlertsPath = '/issues';
export const AlertDetailsPath = (alertId: string, subPath?: string) =>
  subPath ? `${AlertsPath}/${alertId}/${subPath}` : `${AlertsPath}/${alertId}`;
