import { Box } from '@mui/material';
import { H4 } from './Typography';
import LoadingAnimation from './SvgLoadingAnimation';

export default function LoadingState() {
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color: theme.palette.foamGreen['600']
      })}
      data-testid="LoadingState-root"
    >
      <Box sx={(theme) => ({ marginBottom: theme.spacing(4) })}>
        <H4 sx={(theme) => ({ color: theme.palette.grey['700'] })}>loading…</H4>
      </Box>
      <LoadingAnimation height={128} width={128} />
    </Box>
  );
}

export const testIds = {
  loadingState: 'LoadingState-root'
};
