'use client';

import HomeOrgPicker from '@/src/components/HomeOrgPicker';
import LoginLayout from '@/src/components/LoginLayout';

export default function HomeRootPage() {
  return (
    <LoginLayout>
      <HomeOrgPicker />
    </LoginLayout>
  );
}
