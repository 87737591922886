'use client';

import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState
} from 'react';
import { getRuntimeConfig } from '../lib/environment';
import { NatomaRuntimeConfig } from '../lib/config';

export const EnvironmentContext = createContext<NatomaRuntimeConfig>(
  {} as NatomaRuntimeConfig
);

export function EnvironmentProvider({ children }: { children: ReactNode }) {
  const [config, _setConfig] = useState<NatomaRuntimeConfig>();

  useEffect(() => {
    const config = getRuntimeConfig();
    _setConfig(config!);
  }, []);

  return (
    <EnvironmentContext.Provider value={config ?? ({} as NatomaRuntimeConfig)}>
      {!!config && children}
    </EnvironmentContext.Provider>
  );
}

// convenience hook, don't love this pattern everywhere but this is a special case
export default function useEnvironment() {
  return useContext(EnvironmentContext);
}
