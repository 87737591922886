import { Box } from '@mui/material';

export default function SvgLoadingAnimation({
  height = 24,
  width = 24,
  color = 'inherit'
}: {
  height?: number;
  width?: number;
  color?: string;
}) {
  return (
    <Box
      component={'svg'}
      sx={() => ({
        width: '100%',
        '& path.LoadingPath': {
          '@keyframes dash': {
            from: { transform: 'translateX(0px)' },
            to: { transform: 'translateX(-8.4px)' }
          },
          animation: 'dash 1s linear infinite'
        },
        color
      })}
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          className="LoadingPath"
          d="M0 7.44103V7.05556C0 5.9203 0.920303 5 2.05556 5V5C3.19081 5 4.11111 5.9203 4.11111 7.05556V9.94444C4.11111 11.0797 5.03141 12 6.16667 12V12C7.30192 12 8.22222 11.0797 8.22222 9.94444V7.05556C8.22222 5.9203 9.14253 5 10.2778 5V5C11.413 5 12.3333 5.9203 12.3333 7.05556V9.94444C12.3333 11.0797 13.2536 12 14.3889 12V12C15.5241 12 16.4444 11.0797 16.4444 9.94444V7.05556C16.4444 5.9203 17.3647 5 18.5 5V5C19.6353 5 20.5556 5.9203 20.5556 7.05556V9.94444C20.5556 11.0797 21.4759 12 22.6111 12V12C23.7464 12 24.6667 11.0797 24.6667 9.94444V7.05556C24.6667 5.9203 25.587 5 26.7222 5V5C27.8575 5 28.7778 5.9203 28.7778 7.05556V9.94444C28.7778 11.0797 29.6981 12 30.8333 12V12C31.9686 12 32.8889 11.0797 32.8889 9.94444V7.05556C32.8889 5.9203 33.8092 5 34.9444 5V5C36.0797 5 37 5.9203 37 7.05556V8.81949"
          stroke="currentColor"
          strokeWidth="1.2"
        />
      </g>
      <rect
        x="0.6"
        y="0.6"
        width="14.8"
        height="14.8"
        rx="7.4"
        stroke="currentColor"
        strokeWidth="1.2"
      />
      <defs>
        <clipPath id="clip0">
          <rect
            x="1.2"
            y="1.2"
            width="13.6"
            height="13.6"
            rx="8"
            fill="white"
          />
        </clipPath>
      </defs>
    </Box>
  );
}
