import { Box, Paper, Stack } from '@mui/material';
import Logo from './SvgNatomaLogo';

export default function HomeViewPaper({
  children
}: {
  children: React.ReactNode;
}) {
  return (
    <Stack alignItems="center">
      <Box textAlign="center">
        <Logo />
      </Box>
      <Paper
        elevation={4}
        sx={(theme) => ({
          maxWidth: '500px',
          padding: theme.spacing(4),
          background: theme.palette.common.white,
          borderRadius: theme.spacing(4)
        })}
      >
        {children}
      </Paper>
    </Stack>
  );
}
