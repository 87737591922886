export const Environments = [
  'local',
  'debug',
  'test',
  'staging',
  'production'
] as const;
export const RemoteEnvironments = ['staging', 'production'] as const;

export type Environment = (typeof Environments)[number];
export type RemoteEnvironment = Extract<
  (typeof RemoteEnvironments)[number],
  Environment
>;

export function isRemoteEnvironment(env: string): env is RemoteEnvironment {
  return RemoteEnvironments.includes(env as RemoteEnvironment);
}
