import { Box } from '@mui/material';
import { ReactNode } from 'react';

export default function LoginLayout({ children }: { children: ReactNode }) {
  return (
    <Box
      sx={{
        backgroundImage: `url('./login-background.svg')`,
        backgroundRepeat: 'no-repeat',
        backgroundPositionX: 'center',
        backgroundSize: '40%',
        paddingTop: '10%',
        height: '100%'
      }}
    >
      <Box
        sx={{
          maxWidth: 600,
          marginX: 'auto'
        }}
      >
        {children}
      </Box>
    </Box>
  );
}
